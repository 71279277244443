import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import "./css/app.scss";
import Artikel from "./pages/Artikel";
import Tentanglepo from "./pages/Tentanglepo";
import Lepoapp from "./pages/Lepoapp";
import ArtikelDetail from "./pages/ArtikelDetail";
import Promo from "./pages/Promo";
import PromoDetail from "./pages/PromoDetail";
import AOS from "aos";
import Carapake from "./pages/Carapake";
import Syarat from "./pages/Syarat";
import Kebijakan from "./pages/Kebijakan";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 900,
      once: true
    });
  }, []);
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/artikel" exact component={Artikel} />
      <Route path="/artikel/:id" exact component={ArtikelDetail} />
      <Route path="/tentang-lepo" exact component={Tentanglepo} />
      <Route path="/lepo-app" exact component={Lepoapp} />
      <Route path="/cara-pake" exact component={Carapake} />
      <Route path="/promo" exact component={Promo} />
      <Route path="/promo/:id" exact component={PromoDetail} />
      <Route path="/syarat-ketentuan" exact component={Syarat} />
      <Route path="/kebijakan-privasi" exact component={Kebijakan} />
    </Switch>
  );
}

export default App;
