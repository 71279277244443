import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ScrollspyNav from "react-scrollspy-nav";
import axios from "axios";
import MarkdownRenderer from "react-markdown-renderer";

const API_URL = process.env.REACT_APP_API_URL;

const Carapake = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [section, setSection] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const reqdata = axios.get(API_URL + "/category-tutorials");
    axios
      .all([reqdata])
      .then(
        axios.spread((...res) => {
          const data = res[0].data;
          const section = [];
          setData(data);
          setIsLoading(false);
          data.map(item => {
            section.push(`section-${item.id}`);
          });
          setSection(section);
        })
      )
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <div className="carapake-page">
      <Navbar />

      <div className="heading">
        <div className="container">
          <div className="text-heading text-center" data-aos="fade-up">
            Cara Pake Lepo
          </div>
          <div className="text-center" data-aos="fade-up">
            Ayo kita cari tahu cara menggunakan lepo dengan mudah dan bebas dari
            repot!
          </div>
          <div className="d-none d-lg-block" data-aos="fade-up">
            <ScrollspyNav
              scrollTargetIds={section}
              offset={-20}
              activeNavClass="is-active"
              scrollDuration="1000"
              headerBackground="true"
            >
              <ul className="nav submenu justify-content-center">
                {data.map((item, index) => {
                  return (
                    <li className="nav-item" key={index}>
                      <a className="nav-link" href={`#section-${item.id}`}>
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      {/*  */}
      {data.map((item, index) => {
        return (
          <div className="pergroup" key={index} id={`section-${item.id}`}>
            <div className="container">
              <div className="text-heading text-center mb-3">{item.title}</div>
              <div className="desc text-center mb-5">{item.desc}</div>
              <div className="row lists">
                {item.tutorials.map((tutorial, index) => {
                  return (
                    <div className="col-lg-3 pb-3 pb-lg-0" key={index}>
                      {tutorial.image ? (
                        <img
                          src={API_URL + tutorial.image.url}
                          className="w-100 mainimg"
                          alt="..."
                        />
                      ) : null}
                      <div className="title">{tutorial.title}</div>
                      <div className="caption">
                        <MarkdownRenderer markdown={tutorial.desc} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      <Footer />
    </div>
  );
};

export default Carapake;
