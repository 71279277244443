import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../img/logo.svg";
import iconMenu from "../img/icon-menu.svg";
import iconClose from "../img/icon-close.svg";

const Navbar = () => {
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const menus = [
    { text: "Home", path: "/" },
    { text: "Tentang Lepo", path: "/tentang-lepo" },
    { text: "Lepo App", path: "/lepo-app" },
    { text: "Cara Pake", path: "/cara-pake" },
    { text: "Promo", path: "/promo" },
    { text: "Artikel", path: "/artikel" }
  ];
  return (
    <>
      <div className="mynavbar fixed-top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <div data-aos="fade-right">
                <img src={logo} alt="logo" height="35" />
              </div>
            </div>
            <div className="col-auto">
              <div className="d-block d-lg-none">
                <div className="mt-n1" onClick={() => setShowMenuMobile(true)}>
                  <img src={iconMenu} alt="..." height="20" />
                </div>
              </div>
              <div className="d-none d-lg-block">
                <ul className="nav">
                  {menus.map((menu, index) => {
                    return (
                      <li className="nav-item" key={index}>
                        <NavLink exact to={menu.path} className="nav-link">
                          {menu.text}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`menu-mobile ${showMenuMobile ? "d-block" : "d-none"}`}>
        <div className="text-right">
          <div className="closebutton" onClick={() => setShowMenuMobile(false)}>
            <img src={iconClose} alt="..." />
          </div>
        </div>
        {menus.map((menu, index) => {
          return (
            <NavLink key={index} exact to={menu.path} className="nav-item-link">
              {menu.text}
            </NavLink>
          );
        })}
      </div>
    </>
  );
};

export default Navbar;
