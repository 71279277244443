import React from "react";
import imgcs from "../img/cs.svg";
import { Link } from "react-router-dom";

const Csinfo = () => {
  return (
    <div className="csinfo">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-3 col-img">
            <div data-aos="fade-right">
              <img src={imgcs} alt="cs" className="img-fluid" />
            </div>
          </div>
          <div className="col-lg col-text">
            <div data-aos="fade-up">
              <div className="des mb-2 h2">Panduan menggunakan Lepo</div>
            </div>
          </div>
          <div className="col-lg-auto col-more">
            <Link
              style={{ color: "black" }}
              className="btn btn-warning px-5 py-3 rounded-pill font-weight-bold text-black"
              to="/cara-pake"
            >
              Selengkapnya
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Csinfo;
