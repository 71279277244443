import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "axios";
import MarkdownRenderer from "react-markdown-renderer";

const API_URL = process.env.REACT_APP_API_URL;

const Syarat = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    const reqsyarat = axios.get(API_URL + `/syarat-ketentuan`);
    axios
      .all([reqsyarat])
      .then(
        axios.spread((...res) => {
          console.log(res);
          const syarat = res[0].data;
          setData(syarat);
          setIsLoading(false);
        })
      )
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return null;
  }
  return (
    <div className="syarat-page">
      <Navbar />
      <div className="container">
        <div className="main">
          <div
            className="text-heading text-center mt-3 mb-4"
            data-aos="fade-up"
          >
            {data.title}
          </div>
          <div>
            <MarkdownRenderer markdown={data.content} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Syarat;
