import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import imgHero from "../img/lepoapp.svg";
import imgappstore from "../img/Download-appstore.svg";
import imggoogleplay from "../img/Download-Googleplay.svg";
import Csinfo from "../components/Csinfo";
import Footer from "../components/Footer";
import imgBayar from "../img/bayar.svg";
import imgRaih from "../img/raihpoin.svg";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const Lepoapp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [lepoapp, setLepoapp] = useState({
    desc: "",
    title: ""
  });
  const [lepoappList, setLepoappList] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    const reqlepoapp = axios.get(API_URL + "/lepoapp");
    const reqlepoapplist = axios.get(API_URL + "/lepoapp-lists");
    axios
      .all([reqlepoapp, reqlepoapplist])
      .then(
        axios.spread((...res) => {
          console.log(res);
          const lepoapp = res[0].data;
          const lepoapplist = res[1].data;
          setLepoapp(lepoapp);
          setLepoappList(lepoapplist);
          setIsLoading(false);
        })
      )
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="lepoapp-page">
      <Navbar />
      {/* hero */}
      <div className="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div data-aos="fade-right">
                <img src={imgHero} alt="heading" className="w-100" />
              </div>
            </div>
            <div className="col-lg-7">
              <div data-aos="fade-up">
                <div className="text-heading mb-3">{lepoapp.title}</div>
                <div className="des mb-4">{lepoapp.desc}</div>
                <div className="row mx-n2">
                  <div className="col-lg-auto col-12 px-2">
                    <a href={lepoapp.link_googleplay} target="_BLANK">
                      <img src={imggoogleplay} alt="..." height="50" />
                    </a>
                  </div>
                  {lepoapp.link_appstore ? (
                    <div className="col-lg-auto col-12 px-2">
                      <a href={lepoapp.link_appstore} target="_BLANK">
                        <img src={imgappstore} alt="..." height="50" />
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}
      <Csinfo />
      {/* list  */}
      <div className="feature">
        <div className="container feature-container">
          {lepoappList.map((item, index) => {
            return (
              <div
                key={index}
                className="row align-items-center justify-content-lg-between lists"
              >
                <div className="col-lg-6 pb-5 pb-lg-0 col-text">
                  <div
                    data-aos="fade-right"
                    data-aos-delay={index * 100}
                    data-aos-offset="500"
                  >
                    <div className="text-heading mb-3">{item.title}</div>
                    <div className="desc">{item.desc}</div>
                  </div>
                </div>
                <div className="col-lg-5 col-img">
                  <div
                    data-aos="fade-left"
                    data-aos-delay={index * 100}
                    data-aos-offset="500"
                  >
                    <img
                      src={item.image ? API_URL + item.image.url : ""}
                      alt="..."
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            );
          })}

          {/* <div className="row align-items-center justify-content-lg-between lists">
            <div className="col-lg-5 pb-5 pb-lg-0">
              <div data-aos="fade-right" data-aos-offset="800">
                <img src={imgRaih} alt="..." className="w-100" />
              </div>
            </div>
            <div className="col-lg-6">
              <div data-aos="fade-right" data-aos-offset="800">
                <div className="text-heading mb-3">Berinovasi Setiap Hari</div>
                <div className="desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  porta ornare libero, interdum venenatis odio fermentum sed. In
                  imperdiet feugiat est eu varius. Integer ...
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/*  */}
      <Footer />
    </div>
  );
};

export default Lepoapp;
