import React, { useState, useEffect } from "react";
import imgfb from "../img/fb.svg";
import imgtw from "../img/tw.svg";
import imgig from "../img/ig.svg";
import imgphone from "../img/phone.svg";
import imgmail from "../img/mail.svg";
import imgmarker from "../img/marker.svg";
import imggoogle from "../img/Download-Googleplay.svg";
import imgapp from "../img/Download-appstore.svg";
import { Link } from "react-router-dom";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const Footer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    const reqfooter = axios.get(API_URL + "/footer");
    axios
      .all([reqfooter])
      .then(
        axios.spread((...res) => {
          console.log(res);
          const footer = res[0].data;
          setData(footer);
          setIsLoading(false);
        })
      )
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return null;
    // return <Loading show={isLoading} type="full" />;
  }
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-top">
          <div className="row">
            <div className="col-lg-3">
              <div className="head headfirst">Lepo</div>
              <Link to="/tentang-lepo" className="list">
                Tentang Lepo
              </Link>
              <Link to="/artikel" className="list">
                Artikel
              </Link>
              <Link to="/lepo-app" className="list">
                Lepo App
              </Link>
              <a
                href="http://lepoindonesia.com:9091"
                target="_BLANK"
                className="list"
              >
                Web Report
              </a>
              <a
                href="http://lepoindonesia.com/pricelist"
                target="_BLANK"
                className="list"
              >
                Produk
              </a>
            </div>
            <div className="col-lg-3">
              <div className="head">Promo Lepo</div>
              <div className="list">
                <Link to="/promo">Pulsa</Link>
              </div>
              <div className="list">
                <Link to="/promo">Token Listrik</Link>
              </div>
              <div className="list">
                <Link to="/promo">PPOB</Link>
              </div>
            </div>
            <div className="col-lg">
              <div className="head">Ikuti Kami</div>
              <div className="d-flex social-footer">
                <a href={data.link_facebook} target="_BLANK">
                  <img src={imgfb} className="mr-2" alt=".." height="35" />
                </a>
                <a href={data.link_twitter} target="_BLANK">
                  <img src={imgtw} className="mr-2" alt=".." height="35" />
                </a>
                <a href={data.link_instagram} target="_BLANK">
                  <img src={imgig} className="mr-2" alt=".." height="35" />
                </a>
              </div>
            </div>
            <div className="col-lg">
              <div className="d-flex align-items-center mb-3">
                <img
                  src={imgphone}
                  alt="..."
                  width="35"
                  className="mr-lg-4 mr-3"
                />
                <div>{data.phone}</div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img
                  src={imgmail}
                  alt="..."
                  width="35"
                  className="mr-lg-4 mr-3"
                />
                <div>{data.email}</div>
              </div>
              <div className="d-flex align-items-start">
                <img
                  src={imgmarker}
                  alt="..."
                  width="35"
                  className="mr-lg-4 mr-3"
                />
                <div>{data.address}</div>
              </div>
              <div className="row mt-3 mx-n1">
                <div className="col-lg-6 col-6 px-1">
                  <a href={data.link_googleplay} target="_BLANK">
                    <img src={imggoogle} className="w-100" alt="..." />
                  </a>
                </div>
                {data.link_appstore ? (
                  <div className="col-lg-6 col-6 px-1">
                    <a href={data.link_appstore} target="_BLANK">
                      <img src={imgapp} alt="..." className="w-100" />
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row align-items-center">
            <div className="col-lg">
              <ul className="nav">
                <li className="nav-item">
                  <Link to="/syarat-ketentuan" className="nav-link">
                    Syarat & Ketentuan
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/kebijakan-privasi" className="nav-link">
                    Kebijakan Privasi
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-auto pt-bottom">{data.company}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
