import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import MarkdownRenderer from "react-markdown-renderer";

const API_URL = process.env.REACT_APP_API_URL;

const ArtikelDetail = props => {
  const slug = props.match.params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    const reqblogdetail = axios.get(API_URL + `/blogs?slug=${slug}`);
    axios
      .all([reqblogdetail])
      .then(
        axios.spread((...res) => {
          console.log(res);
          const blog = res[0].data[0];
          setBlog(blog);
          setIsLoading(false);
        })
      )
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return null;
    // return <Loading show={isLoading} type="full" />;
  }
  return (
    <div className="artikeldetail-page">
      <Navbar />
      <div className="artikeldetail-content">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg">
              <div className="d-none d-lg-block">
                <ol class="breadcrumb bg-transparent mb-0">
                  <li class="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li class="breadcrumb-item">
                    <Link to="/artikel">Artikel</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {slug}
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-auto">
              <div className="date">{blog.created_at}</div>
            </div>
          </div>
          <div
            className="text-heading text-center mt-3 mb-4"
            data-aos="fade-up"
          >
            {blog.title}
          </div>
          {/* <div className="row justify-content-center mt-5 mb-5">
            <div className="col-lg-5">
              <img
                src="https://www.linkaja.id//uploads/images/news//YW50aWtvZGVfXzE1ODk3OTY1OTBfd2hhdHNhcHAtaW1hZ2UtMjAyMC0wNS0xOC1hdC0xNy0wOS0xOC1qcGVn.jpeg"
                alt="..."
                className="img-news w-100"
              />
            </div>
          </div> */}

          <div className="desc" data-aos="fade-up">
            <MarkdownRenderer markdown={blog.content} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ArtikelDetail;
