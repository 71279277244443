import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import imgBanner from "../img/artikel-heading.svg";
import { Link } from "react-router-dom";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const Artikel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const reqblog = axios.get(API_URL + "/blogs");
    axios
      .all([reqblog])
      .then(
        axios.spread((...res) => {
          console.log(res);
          const blog = res[0].data;
          setArticles(blog);
          setIsLoading(false);
        })
      )
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="artikel-page">
      <Navbar />
      <div className="banner">
        <img src={imgBanner} className="w-100" alt="..." />
      </div>
      <div className="container">
        <div className="text-heading text-center" data-aos="fade-up">
          Artikel
        </div>
        <div className="row lists">
          {articles.map((article, index) => {
            return (
              <div className="col-lg-4 cole" key={index}>
                <div data-aos="fade-up" data-aos-delay={index * 150}>
                  <Link to={`/artikel/${article.slug}`}>
                    <img
                      src={article.image ? API_URL + article.image.url : ""}
                      alt="..."
                      className="w-100"
                    />
                  </Link>
                  <div className="date">{article.created_at}</div>
                  <Link to={`/artikel/${article.slug}`} className="title">
                    {article.title}
                  </Link>
                  <div className="desc">{article.desc}</div>
                  <Link to={`/artikel/${article.slug}`} className="readmore">
                    Read More
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Artikel;
