import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import imgHeading from "../img/about-heading.svg";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const Tentanglepo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [about, setAbout] = useState({
    intro: "",
    desc: "",
    embed: "",
    title: ""
  });
  const [aboutList, setAboutList] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    const reqaboutlist = axios.get(API_URL + "/about-lists");
    const reqabout = axios.get(API_URL + "/about");
    axios
      .all([reqaboutlist, reqabout])
      .then(
        axios.spread((...res) => {
          console.log(res);
          const aboutlist = res[0].data;
          const about = res[1].data;
          setAbout(about);
          setAboutList(aboutlist);
          setIsLoading(false);
        })
      )
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return null;
    // return <Loading show={isLoading} type="full" />;
  }

  return (
    <div className="tentang-page">
      <Navbar />
      <div className="heading">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-img">
              <div data-aos="fade-right">
                <img src={imgHeading} alt="..." className="w-100" />
              </div>
            </div>
            <div className="col-lg-6 col-text">
              <div className="text-heading mb-3" data-aos="fade-up">
                {about.title}
              </div>
              <div className="desc" data-aos="fade-up">
                {about.desc}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container">
        <div className="intro text-center" data-aos="fade-up">
          <div className="row justify-content-center">
            <div className="col-lg-6">{about.intro}</div>
          </div>
        </div>
        <div className="embed">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  class="embed-responsive-item"
                  src={about.embed}
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="tentang-container">
          {aboutList.map((item, index) => {
            return (
              <div
                key={index}
                className="row align-items-center justify-content-lg-between lists"
              >
                <div
                  className="col-lg-5 pb-5 pb-lg-0 col-text"
                  data-aos="fade-right"
                  data-aos-delay={index * 100}
                >
                  <div className="text-heading mb-3 mb-lg-3">{item.title}</div>
                  <div className="desc">{item.desc}</div>
                </div>
                <div
                  className="col-lg-6 col-img"
                  data-aos-delay={index * 100}
                  data-aos="fade-left"
                >
                  <img
                    src={item.image ? API_URL + item.image.url : ""}
                    alt="..."
                    className="w-100"
                  />
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className="row align-items-center justify-content-lg-between lists">
          <div
            className="col-lg-6 pb-5 pb-lg-0"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <img src={imgPerjalanan} alt="..." className="w-100" />
          </div>
          <div className="col-lg-5" data-aos="fade-left" data-aos-delay="100">
            <div className="text-heading mb-3 mb-lg-3">
              Berinovasi Setiap Hari
            </div>
            <div className="desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              porta ornare libero, interdum venenatis odio fermentum sed. In
              imperdiet feugiat est eu varius. Integer ...
            </div>
          </div>
        </div> */}
      </div>

      <Footer />
    </div>
  );
};

export default Tentanglepo;
