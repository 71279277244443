import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import imgappstore from "../img/Download-appstore.svg";
import imggoogleplay from "../img/Download-Googleplay.svg";
import Csinfo from "../components/Csinfo";
import imgpulsa from "../img/beli-pulsa.svg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Galeri from "../components/Galeri";

const API_URL = process.env.REACT_APP_API_URL;

const Home = () => {
  const [home, setHome] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [galeri, setGaleri] = useState([]);
  const [benefits, setBenefits] = useState([
    { text: "Bayar Tagihan", imageUrl: imgpulsa },
    { text: "Beli Pulsa / Data", imageUrl: imgpulsa },
    { text: "Customer Service", imageUrl: imgpulsa },
    { text: "Belanja Online", imageUrl: imgpulsa }
  ]);
  const [promos, setPromos] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const reqgaleri = axios.get(API_URL + "/galeris");
    const reqbenefit = axios.get(API_URL + "/benefits");
    const reqpromo = axios.get(API_URL + "/promos?_limit=3");
    const reqhome = axios.get(API_URL + "/home");
    axios
      .all([reqgaleri, reqbenefit, reqpromo, reqhome])
      .then(
        axios.spread((...res) => {
          console.log(res);
          const galeri = res[0].data;
          const benefit = res[1].data;
          const promo = res[2].data;
          const home = res[3].data;
          setGaleri(galeri);
          setBenefits(benefit);
          setPromos(promo);
          setHome(home);
          setIsLoading(false);
        })
      )
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return null;
    // return <Loading show={isLoading} type="full" />;
  }
  return (
    <div className="home-page">
      <Navbar />
      {/* hero */}
      <div className="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="text-heading mb-3">
                <div data-aos="fade-down">{home.hero_title}</div>
              </div>
              <div className="des mb-4">
                <div data-aos="fade-down" data-aos-delay="50">
                  {home.hero_desc}
                </div>
              </div>

              <div data-aos="fade-down" data-aos-delay="100">
                <div className="d-lg-block d-none">
                  <div className="row mx-n2">
                    <div className="col-lg-auto col-12 px-2">
                      <a href={home.link_googleplay} target="_BLANK">
                        <img src={imggoogleplay} alt="..." height="50" />
                      </a>
                    </div>
                    {home.link_appstore ? (
                      <div className="col-lg-auto col-12 px-2">
                        <a href={home.link_appstore} target="_BLANK">
                          <img src={imgappstore} alt="..." height="50" />
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div data-aos="fade-left">
                <img
                  src={home.hero_image ? API_URL + home.hero_image.url : ""}
                  alt="heading"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}
      <Csinfo />
      {/* benefit */}
      <div className="benefit">
        <div className="container">
          <div className="text-heading text-center" data-aos="fade-up">
            {home.benefit_title}
          </div>
          <div className="row lists">
            {benefits.map((benefit, index) => {
              return (
                <div className="col-lg-3 pb-4 pb-lg-0" key={index}>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-offset="400"
                  >
                    <img
                      src={benefit.image ? API_URL + benefit.image.url : ""}
                      className="w-100"
                    />
                  </div>

                  <div
                    className="text"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-offset="400"
                  >
                    {benefit.title}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* end */}
      {/* promo */}
      <div data-aos="fade-up">
        <div className="promo-home">
          <div className="container">
            <div className="text-heading text-center" data-aos="fade-up">
              {home.promo_title}
            </div>
            <div
              className="sub-heading text-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {home.promo_desc}
            </div>
            <div data-aos="fade-up" data-aos-delay="200" data-aos-offset="400">
              <div className="row lists">
                {promos.map((promo, index) => {
                  return (
                    <div className="col-lg-4 pb-4 pb-lg-0" key={index}>
                      <Link to={`/promo/${promo.id}`}>
                        <img
                          src={promo.image ? API_URL + promo.image.url : ""}
                          className="w-100"
                        />
                      </Link>
                      <Link to={`/promo/${promo.id}`} className="title">
                        {promo.title}
                      </Link>
                      <div className="des">{promo.desc}</div>
                      <div className="expdate text-primary">
                        Berlaku hingga {promo.expiredate}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="text-center">
              <Link
                to="/promo"
                className="btn btn-primary rounded-pill readmore"
              >
                Lihat Semua Promo
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      {/* galeri */}
      <div data-aos="fade-up">
        <div className="galeri">
          <div className="container">
            <div className="text-heading text-center mb-5">
              {home.galeri_text}
            </div>
            <Galeri data={galeri} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
