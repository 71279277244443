import React, { Component } from "react";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import iconLeft from "../img/arrow-left.svg";
import iconRight from "../img/arrow-right.svg";

const API_URL = process.env.REACT_APP_API_URL;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="slider-arrow-right" onClick={onClick}>
      <img src={iconRight} alt="..." />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="slider-arrow-left" onClick={onClick}>
      <img src={iconLeft} alt="..." />
    </div>
  );
}

export default class Galeri extends Component {
  render() {
    const settings = {
      dots: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
        <Slider {...settings}>
          {this.props.data.map((item, index) => {
            return (
              <div key={index} className="px-2">
                <img
                  src={item.image ? API_URL + item.image.url : ""}
                  className="w-100 mb-3"
                  alt="..."
                />
                <div className="h5">{item.caption}</div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
