import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const Promo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [promos, setPromos] = useState([]);
  const [promosNew, setPromosNew] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    const reqpromo = axios.get(API_URL + "/promos");
    const reqpromonew = axios.get(API_URL + "/promos?_limit=3");
    axios
      .all([reqpromo, reqpromonew])
      .then(
        axios.spread((...res) => {
          const promo = res[0].data;
          const promonew = res[1].data;
          setPromos(promo);
          setPromosNew(promonew);
          setIsLoading(false);
        })
      )
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return null;
  }
  return (
    <div className="promo-page">
      <Navbar />
      {/*  */}
      <div className="new">
        <div className="container">
          <div className="text-heading text-center mb-4" data-aos="fade-up">
            Promo Terbaru
          </div>
          <div className="row lists">
            {promosNew.map((promo, index) => {
              return (
                <div className="col-lg-4 pb-4 pb-lg-0" key={index}>
                  <div data-aos="fade-up" data-aos-delay={index * 150}>
                    <Link to={`/promo/${promo.id}`}>
                      <img
                        src={promo.image ? API_URL + promo.image.url : ""}
                        className="w-100"
                      />
                    </Link>
                    <Link to={`/promo/${promo.id}`} className="title">
                      {promo.title}
                    </Link>
                    <div className="des">{promo.desc}</div>
                    <div className="expdate text-primary">
                      Berlaku hingga {promo.expiredate}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/*  */}

      {/*  */}
      <div className="other">
        <div className="container">
          <div className="text-heading text-center mb-4" data-aos="fade-up">
            Promo Lainnya
          </div>
          <div className="row lists">
            {promos.map((promo, index) => {
              return (
                <div className="col-lg-4 pb-4" key={index}>
                  <div data-aos="fade-up" data-aos-delay={index * 100}>
                    <Link to={`/promo/${promo.id}`}>
                      <img
                        src={promo.image ? API_URL + promo.image.url : ""}
                        className="w-100"
                      />
                    </Link>
                    <Link to={`/promo/${promo.id}`} className="title">
                      {promo.title}
                    </Link>
                    <div className="des">{promo.desc}</div>
                    <div className="expdate text-primary">
                      Berlaku hingga 25 Juni 2020 {promo.expiredate}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/*  */}
      <Footer />
    </div>
  );
};

export default Promo;
